<vbn-full-logo></vbn-full-logo>
<div class="mat-label-large footer-text">
  <span>4262 Wright Street, Abbotsford, BC V2S7Y8</span>
  <span>|</span>
  <span>1-888-826-2355</span>
  <span>|</span>
  <span>youngplants&commat;vanbelle.com</span>
</div>

<span style="flex: 1"></span>

<button mat-icon-button (click)="scrollUp.emit()">
  <mat-icon fontSet="fa-regular" fontIcon="fa-circle-up" inline></mat-icon>
</button>
