import { Component, computed, inject, signal, viewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { LogoComponent } from '../logo/logo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserInfoComponent } from '../user-info/user-info.component';
import { UserStore } from '../../modules/shared/store/user/user.store';
import { exitFullScreen, isFullScreen, requestFullScreen } from '../../modules/shared/utils';
import { FooterComponent } from '@cportal/components';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cp-layout',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatListItem,
    MatMenu,
    RouterOutlet,
    MatToolbar,
    MatSidenavContent,
    MatNavList,
    MatSidenav,
    MatSidenavContainer,
    RouterLink,
    MatMenuTrigger,
    MatMenuItem,
    RouterLinkActive,
    LogoComponent,
    NgIf,
    MatButton,
    MatTooltipModule,
    MatAnchor,
    UserInfoComponent,
    NgClass,
    FooterComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  readonly store = inject(UserStore);
  router = inject(Router);
  ngDocument = inject(DOCUMENT);
  sidenavContent = viewChild<MatSidenavContent>('sidenavContent');
  isCollapsed = signal(false);

  navItems = [
    { link: '/dashboard', label: 'Dashboard', icon: 'fa-gauge' },
    { link: '/my-orders', label: 'My Orders', icon: 'fa-cubes' },
    { link: '/billing', label: 'Billing', icon: 'fa-money-bill-1' },
    { link: '/sales-materials', label: 'Sales Materials', icon: 'fa-bullseye' },
    { link: '/support', label: 'Support', icon: 'fa-comments' },
    { link: '/settings', label: 'Settings', icon: 'fa-gear' },
  ];
  user = this.store.user;
  protected readonly isFullScreen = isFullScreen;
  private xSmallObserver = toSignal(inject(BreakpointObserver).observe([Breakpoints.XSmall]));
  isSmallScreen = computed(() => this.xSmallObserver()?.matches);

  async logout() {
    await this.store.logout();
  }

  collapse() {
    this.isCollapsed.update(collapsed => !collapsed);
  }

  navigateToSettings() {
    this.router.navigate(['./settings']);
  }

  toggleFullScreen() {
    if (isFullScreen()) {
      exitFullScreen(this.ngDocument);
    } else {
      requestFullScreen(this.ngDocument.documentElement);
    }
  }

  scrollUp() {
    this.sidenavContent()?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onNavItemClick() {
    if (this.isSmallScreen()) {
      this.collapse();
    }
  }
}
