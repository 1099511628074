<vbn-simple-banner
  [title]="'Welcome, ' + (!isUserLoading() && !!user() ? user().firstName : '')"
  [subTitle]="(today | date:'dd MMM yyyy') + ', Week ' +(today | date:'w')"
/>
<div class="mat-body-medium content">
  <div class="column">
    <vbn-orders-status-timeline
      [orders]="ordersTimeline()"
      [isLoading]="isLoading()"
      (orderClick)="onOrderClick($event)"
    ></vbn-orders-status-timeline>

    <mat-card>
      <mat-card-header>
        <span class="mat-title-small --uppercase">Your Partner / Support </span>
      </mat-card-header>
      <mat-card-content class="contacts-wrapper">
        <div class="contact">
          <img
            [ngSrc]="contacts().salesRepPhotoUrl || 'https://placehold.co/212'"
            width="212"
            height="212"
            [alt]="contacts().salesRepName"
            priority
          />
          <b>{{ contacts().salesRepName }}</b>
          <span>{{ contacts().salesRepTitle }}</span>
          <span><b>M&nbsp;</b>{{ contacts().salesRepMobileNo }}</span>
          <span>{{ contacts().salesRepEmail }}</span>
        </div>

        <mat-divider vertical></mat-divider>

        <div class="contact">
          <img
            [ngSrc]="contacts().insideSupportPhotoUrl || 'https://placehold.co/212'"
            [alt]="contacts().insideSupportName"
            height="212"
            width="212"
            priority
          />
          <b>{{ contacts().insideSupportName }}</b>
          <span>{{ contacts().insideSupportTitle }}</span>
          <span><b>M&nbsp;</b>{{ contacts().insideSupportMobileNo }}</span>
          <span>{{ contacts().insideSupportEmail }}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="column">
    <mat-card>
      <mat-card-header>
        <span class="mat-title-small --uppercase">{{ slot1().title }}</span>
      </mat-card-header>
      <mat-card-content>
        <img
          class="publication"
          [ngSrc]="slot1().imageUrl || 'https://placehold.co/338'"
          width="338"
          height="338"
          [alt]="slot1().title"
          (click)="handleClick(slot1())"
          priority
        />
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <span class="mat-title-small --uppercase">{{ slot2().title }}</span>
      </mat-card-header>
      <mat-card-content>
        <img
          class="publication"
          [ngSrc]="slot2().imageUrl || 'https://placehold.co/320'"
          width="338"
          height="338"
          [alt]="slot2().title"
          (click)="handleClick(slot2())"
          priority
        />
      </mat-card-content>
    </mat-card>
  </div>
</div>
