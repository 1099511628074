<mat-sidenav-container class="sidenav-container" [autosize]="true">
  <mat-sidenav
    [mode]="'side'"
    opened
    [style.width.px]="isSmallScreen() ? (isCollapsed() ? 124 : 0 ): (isCollapsed() ? 68: 124)"
  >
    @if (!isSmallScreen()) {
      <cp-logo [isCollapsed]="isCollapsed()"></cp-logo>
    }
    <cp-user-info
      *ngIf="!isCollapsed() || isSmallScreen()"
      [user]="user()"
      (profileClick)="navigateToSettings()"
    ></cp-user-info>
    <mat-nav-list class="sidenav-item">
      @for (nav of navItems; track nav) {
        <a
          mat-list-item
          [routerLink]="nav.link"
          [routerLinkActive]="'active'"
          [matTooltip]="isCollapsed() && !isSmallScreen() ? nav.label : ''"
          matTooltipPosition="right"
          (click)="onNavItemClick()"
        >
          <mat-icon inline class="fa-lg" fontSet="fa" [fontIcon]="nav.icon"></mat-icon>
          <div [hidden]="isCollapsed() && !isSmallScreen()" class="mat-body-small link-label">
            {{ nav.label }}
          </div>
        </a>
      }
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content #sidenavContent>
    <mat-toolbar>
      <button mat-icon-button (click)="collapse()">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button class="mat-body-large" (click)="toggleFullScreen()">
        <mat-icon
          inline
          fontSet="fa"
          [fontIcon]="isFullScreen() ? 'fa-minimize' : 'fa-maximize'"
          [matTooltip]="isFullScreen() ? 'Exit Fullscreen': 'Go Fullscreen'"
        ></mat-icon>
      </button>
      @if (isSmallScreen()) {
        <span style="flex: 1 0 0"></span>
        <span class="mat-title-medium inline-logo"><b>YP BUYER'S PORTAL</b></span>
      }
      <span style="flex: 1 0 0"></span>
      <a mat-button (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span class="mat-body-small">Log out</span>
      </a>
    </mat-toolbar>
    <main>
      <router-outlet></router-outlet>
    </main>
    <vbn-footer (scrollUp)="scrollUp()"></vbn-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
