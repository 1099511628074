import { Component, inject } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader } from '@angular/material/card';
import {
  OrdersStatusTimelineComponent,
  PublicationsComponent,
  SimpleBannerComponent,
  StatusBadgeComponent,
} from '@cportal/components';
import { MatDivider } from '@angular/material/divider';
import { UserStore } from '../shared/store/user/user.store';
import { DatePipe, JsonPipe, NgOptimizedImage } from '@angular/common';
import {
  ContactsJson,
  PublicationAction,
  PublicationJson,
  SalesOrderTimelineJson,
  UserJson,
} from '@cportal/api';
import { downloadFile } from '../shared/utils';
import { DashboardStore } from '../shared/store/dashboard/dashboard.store';
import { DeepSignal } from '@ngrx/signals';
import { Router } from '@angular/router';

@Component({
  selector: 'cp-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  imports: [
    MatCard,
    MatCardHeader,
    MatCardContent,
    StatusBadgeComponent,
    SimpleBannerComponent,
    MatDivider,
    JsonPipe,
    PublicationsComponent,
    NgOptimizedImage,
    DatePipe,
    OrdersStatusTimelineComponent,
  ],
})
export class DashboardComponent {
  private readonly router = inject(Router);
  private readonly userStore = inject(UserStore);
  private readonly dashboardStore = inject(DashboardStore);
  readonly user: DeepSignal<UserJson> = this.userStore.user;
  readonly slot1: DeepSignal<PublicationJson> = this.dashboardStore.slot1;
  readonly slot2: DeepSignal<PublicationJson> = this.dashboardStore.slot2;
  readonly contacts: DeepSignal<ContactsJson> = this.dashboardStore.contacts;
  readonly isLoading: DeepSignal<boolean> = this.dashboardStore.isLoading;
  readonly ordersTimeline: DeepSignal<SalesOrderTimelineJson[]> =
    this.dashboardStore.ordersTimeline;
  readonly isUserLoading = this.userStore.isLoading;
  readonly today = Date.now();

  handleClick(publication: PublicationJson) {
    if (publication.action === PublicationAction.Open) {
      window.open(publication.linkUrl, '_blank');
    } else if (publication.action === PublicationAction.Download) {
      downloadFile(`${publication.linkUrl}`);
    }
  }

  onOrderClick(order: SalesOrderTimelineJson) {
    this.router.navigate([
      'my-orders/details',
      { outlets: { 'order-details': [order.salesOrderId, order.portalOrderId] } },
    ]);
  }
}
