import { Component, output } from '@angular/core';
import { FullLogoComponent } from './full-logo.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'vbn-footer',
  standalone: true,
  imports: [FullLogoComponent, MatIcon, MatIconButton],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  scrollUp = output();
}
