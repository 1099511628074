import { Component } from '@angular/core';

@Component({
  selector: 'vbn-full-logo',
  standalone: true,
  templateUrl: './full-logo.svg',
  styles: [
    `
        svg {
            height: 32px;
        }
    `,
  ],
})
export class FullLogoComponent {}
